<template>
	<!-- 包装列表 -->
	<div class="adh-card-pakeage el-content">
		<a-button v-has="{action:'wxapp_marketing_card_package_add'}" type="primary" @click="showAdd(0)">
			<i class="ri-add-line"></i>添加包装信息
		</a-button>
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'包装',dataIndex:'package',slots:{customRender:'package'}},
			{title:'价格',dataIndex:'price'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #package="{record}">
				<div style="display: flex;align-items: center;">
					<img :src="record.cover" alt="" style="width: 30px;height: 30px;margin-right: 10px;">
					<span>{{record.name}}</span>
				</div>
			</template>
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_marketing_card_package_add'}"  @click="showAdd(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_card_package_del'}"  @click="deletePackage(record.id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getPackAge(info.page,e)}"
				@change="(e)=>{getPackAge(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.add" title="添加/编辑包装信息" @ok="saveForm" width="600px" @cancel="show.add = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="包装名称">
					<a-input v-model:value="addForm.name" placeholder="包装名称"></a-input>
				</a-form-item>
				<a-form-item label="价格">
					<a-input v-model:value="addForm.price" placeholder="价格" addon-after="元"></a-input>
				</a-form-item>
				<a-form-item label="封面">
					<kd-img-select :src="addForm.cover" @change="(url)=>{ addForm.cover = url }"></kd-img-select>
				</a-form-item>
				<a-form-item label="简介">
					<a-textarea v-model:value="addForm.summary" placeholder="简介" :rows="4" />
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import cardModel from '@/api/addons/card'
import commonModel from '@/api/common'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			},
			show:{
				add:false,
			},
			addForm:null
		})
		getPackAge(1,state.info.limit)

		function getPackAge(page,limit){
			cardModel.getCardPackage(page,limit,res=>state.info = {limit,...res})
		}

		function showAdd(row){
			state.addForm = {
				id:row ? row.id :0,
				name:row ? row.name:'',
				summary:row ? row.summary :'',
				price:row ? row.price :'',
				cover:row ? row.cover : ""
			}
			state.show.add = true
		}

		const saveForm = ()=>cardModel.addOrEditPackage(state.addForm,()=>{
			state.show.add = false
			getPackAge(state.info.page,state.info.limit)
		})

		const deletePackage = (id)=>commonModel.deleteDataList(id,44,'确认删除包装信息吗？').then(()=>{
			getPackAge(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			saveForm,
			getPackAge,
			showAdd,
			deletePackage
		}
	}
}
</script>

<style>
</style>
